import { HYDRATE } from 'next-redux-wrapper';

import {
    createSlice,
    createAction,
    createAsyncThunk,
    isAnyOf,
} from '@reduxjs/toolkit';
import { getReq } from 'api';

const hydrate = createAction(HYDRATE);

export const fetchStoryProducts = createAsyncThunk(
    'products/fetchStoryProducts',
    async (_, { rejectWithValue }) => {
        try {
            const res = await getReq(`/payments/stories/prices`);

            return res.data.data;
        } catch (err) {
            let error = err;
            if (!error.response) {
                throw err;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

const initialState = {};

const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        reset: () => initialState,
        setPaymentDetails: (state, { payload }) => {
            state.payment = payload;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(hydrate, (state, action) => {
                return {
                    ...state,
                    ...action.payload.products,
                };
            })
            .addCase(fetchStoryProducts.fulfilled, (state, { payload }) => {
                state.data = payload;
                state.loading = false;
                state.error = null;
            })
            .addMatcher(isAnyOf(fetchStoryProducts.pending), (state) => {
                state.loading = true;
                state.error = null;
            })
            .addMatcher(
                isAnyOf(fetchStoryProducts.rejected),
                (state, { payload }) => {
                    state.loading = false;
                    state.error = payload;
                }
            );
    },
});

export const { reset, setPaymentDetails } = productsSlice.actions;

export const productsSelector = ({ products }) => products;

export default productsSlice.reducer;
